import toast from "react-hot-toast"
import { api } from "../configs/axiosConfigs"

export const authApi = {
  async login(data) {
    const response = await toast.promise(api.post("/login", data), {
      loading: 'Carregando...',
      success: 'Login realizado.',
      error: 'Erro ao realizar login!'
    }, {
      style: {
        minWidth: '250px',
        minHeight: '75px'
      }
    })

    return response.data
  },

  async logout() {
    const response = api.get('/logout')

    toast.success('Logout realizado com sucesso!')

    return response.data
  },

  async forgotPassword(data) {
    const response = await api.post('/forgot-password', data)

    return response
  },

  async resetPassword(data, token) {
    const axiosConfigs = {
      headers: {
        token: `${token}`
      }
    }

    const response = await api.post('/reset-password', data, axiosConfigs)

    return response
  }
}