import BaseApi from "../baseApi"

const baseApi = new BaseApi('board')

export const boardApi = {

    async getAll() {
        const response = await baseApi.getAll()

        return response
    },

    async getOne(id) {
        const response = await baseApi.getOne(id)

        return response
    },

    async create(data) {
        const response = await baseApi.create(data)

        return response
    },

    async update(id, data) {
        const response = await baseApi.update(id, data)

        return response
    },

    async delete(id) {
        const response = await baseApi.delete(id)

        return response
    }
}