
import BaseApi from "../baseApi"
import { api } from "../configs/axiosConfigs"

const baseApi = new BaseApi('workspace')

export const workspaceApi = {

    async getAll() {
        const response = await baseApi.getAll()

        return response
    },

    async getOne(id) {
        const response = await baseApi.getOne(id)

        return response
    },

    async create(data) {
        const response = await baseApi.create(data)

        return response
    },

    async update(id, data) {
        const response = await baseApi.update(id, data)

        return response
    },

    async delete(id) {
        const response = await baseApi.delete(id)

        return response
    },

    async workspaceByUser() {
        const response = await api.get(`/all_workspaces_by_user/`)

        return response.data
    }
}