
import BaseApi from "../baseApi"
import { api } from "../configs/axiosConfigs"

const baseApi = new BaseApi('task')

export const taskApi = {

    async getAll() {
        const response = await baseApi.getAll()

        return response
    },

    async getOne(id) {
        const response = await baseApi.getOne(id)

        return response
    },

    async create(data) {
        const response = await baseApi.create(data)

        return response
    },

    async update(id, data) {
        const response = await baseApi.update(id, data)

        return response
    },

    async delete(id) {
        const response = await baseApi.delete(id)

        return response
    },

    async moveTask(task_id, column_id) {
        const response = await api.put(`/move_task/${task_id}/${column_id}`)

        return response.data
    },

    async getAllTasksOfBoard(board_id) {
        const response = await api.get(`/all_tasks_in_board/${board_id}`)

        return response.data
    }
}