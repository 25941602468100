import toast from "react-hot-toast"
import { api } from "./configs/axiosConfigs"

export const toastStyleConfig = {
  style: {
    minWidth: '250px',
    minHeight: '75px'
  }
}

export const toastGetMessage = {
  loading: 'Carregando...',
  success: 'Dado Carregado.',
  error: 'Erro ao carregar dado!'
}

export const toastGetAllMessage = {
  loading: 'Carregando...',
  success: 'Dados Carregados.',
  error: 'Erro ao carregar dados!'
}

export const toastCreateMessage = {
  loading: 'Carregando...',
  success: 'Dado criado com sucesso.',
  error: 'Erro ao criar dado!'
}

export const toastUpdateMessage = {
  loading: 'Carregando...',
  success: 'Dado atualizado com sucesso.',
  error: 'Erro ao atualizar dado!'
}

export const toastEmailSentMessage = {
  loading: 'Enviando email..',
  success: 'Email enviado com sucesso.',
  error: 'Erro ao atualizar dado!'
}

export const toastDeleteMessage = {
  loading: 'Carregando...',
  success: 'Dado deletado com sucesso.',
  error: 'Erro ao deletar dado!'
}

export const toastActiveMessage = {
  loading: 'Carregando...',
  success: 'Dado ativado com sucesso.',
  error: 'Erro ao deletar dado!'
}

export const toastCodeConfirmMessage = {
  loading: 'Confirmando...',
  success: 'Código confirmado com sucesso.',
  error: 'Erro ao confirmar código!'
}

export default class BaseApi {
  constructor(route) {
    this.route = route
  }

  async getAll(id, config) {
    let response = []
    let url = `/${this.route}`
  
    if (id) {
      url += `?page=${id}`
    }
  
    try {
      response = config ? await api.get(url, config) : await api.get(url)
    } catch (error) {
      const { code, response: errorResponse } = error
  
      if (code !== 'ERR_CANCELED') {
        toast.error(errorResponse?.data?.title ?? "Erro ao carregar os dados.", { duration: 30000 })
      } else {
        toast.remove()
      }
      
      throw error
    }
  
    return response?.data ?? []
  }
  

  async get(url, config) {

    let response = []

    try {
      if (config) {
        response = await api.get(url, config)
      } else {
        response = await api.get(url)
      }
    } catch (error) {
      
      if (error?.code !== 'ERR_CANCELED') {
        let message = "Erro ao carregar os dados."
        if (error?.response?.data?.messages) {
          message = error?.response?.data?.messages
        }
        toast.error(error?.response?.data?.title ?? message, { duration: 15000 })
      }
      if (error?.code === 'ERR_CANCELED') {
        toast.remove()
      }
      throw error
    }

    if (response) {
      return response
    } else {
      return []
    }

  }

  async getAllPaginated(id) {

    const response = await api.get(`/${this.route}?page=${id}`).catch((err) => console.log(err))

    if (response.data) {
      return response.data
    } else {
      return []
    }
  }

  async getOne(id, config) {

    let response = []

    try {

      if (config) {
        response = await api.get(`/${this.route}/${id}`, config)
      } else {
        response = await api.get(`/${this.route}/${id}`)
      }
    } catch (error) {
      if (error?.code !== 'ERR_CANCELED') {
        toast.error(error?.response?.data?.title ?? "Erro ao carregar os dados.", { duration: 30000 })
      }
      if (error?.code === 'ERR_CANCELED') {
        toast.remove()
      }
      throw error
    }
    
    if (response.data) {
      return response.data
    }
  }

  async create(data, config) {
    try {
      const response = await toast.promise(api.post(`/${this.route}`, data, config), toastCreateMessage, toastStyleConfig)
      
      return response.data
      
    } catch (error) {
      
      if (error.response.data.status === 422) {
        toast.error(error.response.data.title, { duration: 60000 })
      }

      throw error

    }
  }

  async update(id, data) {
    try {
      const response = await toast.promise(api.put(`/${this.route}/${id}`, data), toastUpdateMessage, toastStyleConfig)

      return response.data

    } catch (error) {

      if (error.response.data.status === 422) {
        toast.error(error.response.data.title, { duration: 60000 })
      }

      throw error

    }
  }

  async delete(id) {
    const response = await toast.promise(api.delete(`/${this.route}/${id}`), toastDeleteMessage, toastStyleConfig)

    return response.data
  }

  async active(id) {
    const response = await toast.promise(api.get(`/${this.route}/active/${id}`), toastActiveMessage, toastStyleConfig)

    return response
  }

  async patch(url, data = null) {
    const response = await toast.promise(api.patch(url, data), toastUpdateMessage, toastStyleConfig)

    return response.data
  }

}